const API = {
  GetHomeData: {
    method: 'post',
    url: '/home'
  },
  GetTeam: {
    method: 'post',
    url: '/home/supervise_list'
  },
  GetAcademyPhoto: {
    method: 'post',
    url: '/culture/studyGroup_list'
  },
  GetActivity: {
    method: 'post',
    url: '/culture/activity_list'
  },
  GetNews: {
    method: 'post',
    url: '/news/list'
  },
  NewsDetails: {
    method: 'post',
    url: '/news/details'
  },
  TeamDetails: {
    method: 'post',
    url: '/home/supervise_details'
  },
  ActivityDetails: {
    method: 'post',
    url: '/culture/activity_details'
  },
  GetMaster: {
    method: 'post',
    url: '/brand/supervise_list'
  },
  MasterDetails: {
    method: 'post',
    url: '/brand/supervise_details'
  },
  GetCooperation: {
    method: 'post',
    url: '/brand/partners_list'
  }
}
export default API
