<template>
  <div class="Foot">
    <div class="Foot_content">
      <div class="select">
        <div class="title" @click="isSelect = !isSelect">
          <p>旗下产业</p>
          <i class="el-icon-arrow-down" :class="isSelect ? 'isRotate' : ''"></i>
        </div>
        <div class="list" :class="isSelect ? 'isHeight' : ''">
          <ul>
            <li v-for="(item, index) in Select" :key="index">
              <a :href="item.href">
                <div class="select_icon">
                  <img :src="item.imgSrc" />
                </div>
                <p>{{ item.title }}</p>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="Foot_contact">
        <div class="title">联系我们</div>
        <span></span>
        <ul>
          <li>
            <p>湖北省武汉市洪山区群光中心3406（国内）</p>
            <p>616-4798 Yonge Street, North York, ON M2N 0G3（Canada）</p>
          </li>
          <li>
            <p>400-686-9191 中国（CN）</p>
            <p>416-225-2666 加拿大（CA）</p>
          </li>
          <li>
            <p>邮箱：info@csoedu.com</p>
          </li>
        </ul>
      </div>
      <div class="Foot_ewm">
        <p>微信公众号二维码</p>
        <img src="http://cso-group.oss-cn-hongkong.aliyuncs.com/CISO/common/img/Foot_ewm.png" width="93px" />
      </div>
    </div>
    <div class="Foot_bottom">
      <p><a href="https://beian.miit.gov.cn/" target="_blank">鄂ICP备2021009136号-1</a></p>
      <div class="GoTop">
        <div class="GoTop_icon" @click="BackTop">
          <span></span>
          <span></span>
          <span></span>
        </div>
        <p @click="BackTop">回到顶部</p>
      </div>
      <p><a href="https://beian.miit.gov.cn/" target="_blank">鄂ICP备2021009136号-1</a></p>
    </div>
  </div>
</template>
<script>
import '../../plugins/element'
export default {
  data() {
    return {
      isSelect: false,
      Select: [
        {
          href: 'http://www.uaischool.com',
          imgSrc: 'http://cso-group.oss-cn-hongkong.aliyuncs.com/CISO/common/img/Foot_select_icon1.png',
          title: 'UAI国际学分课程平台'
        },
        {
          href: 'http://www.csoschool.com',
          imgSrc: 'http://cso-group.oss-cn-hongkong.aliyuncs.com/CISO/common/img/Foot_select_icon2.png',
          title: 'CSO国际教育中心'
        },
        {
          href: 'http://us.csosummer.cn/',
          imgSrc: 'http://cso-group.oss-cn-hongkong.aliyuncs.com/CISO/common/img/Foot_select_icon3.png',
          title: 'TA MOOC'
        },
        {
          href: '#',
          imgSrc: 'http://cso-group.oss-cn-hongkong.aliyuncs.com/CISO/common/img/Foot_select_icon5.png',
          title: '西索国际MBA'
        }
      ]
    }
  },
  methods: {
    BackTop() {
      this.$emit('isBackTop')
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../assets/common/css/Foot.less';
</style>
