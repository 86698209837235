<template>
  <div class="Banner">
    <div class="Banner_Img" :style="{ backgroundImage: `url('http://cso-group.oss-cn-hongkong.aliyuncs.com/CISO/common/img/${BannerName}.png')` }"></div>
    <div class="Menu">
      <ul>
        <li v-for="(item, index) in MenuList" :key="index" :class="MenuActive == index ? 'Menu_active' : ''">
          <a :href="item.href">{{ item.name }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import Menu from '../../plugins/Menu'
export default {
  props: {
    BannerName: {
      type: String,
      default: ''
    },
    MenuName: {
      type: String,
      default: ''
    },
    MenuActive: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      MenuList: []
    }
  },
  beforeMount() {
    this.GetMenu()
  },
  methods: {
    GetMenu() {
      Menu.forEach((item, index) => {
        if (item.name == this.MenuName) {
          this.MenuList = item.children
        } else {
          item.children.forEach((Subitem) => {
            if (Subitem.name == this.MenuName) {
              this.MenuList = Menu[index].children
            }
          })
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.Banner {
  width: 100%;
  height: 610px;
  overflow: hidden;
  margin-top: 100px;
  .Banner_Img {
    width: 100%;
    height: 530px;
    overflow: hidden;
    background-size: 100% auto;
    background-repeat: no-repeat;
  }
  .Menu {
    width: 100%;
    height: 80px;
    min-width: 1440px;
    overflow: hidden;
    padding: 0 20px;
    background-color: #ffffff;
    border-bottom: 1px solid #d1d1d1;
    ul {
      display: flex;
      align-items: center;
      justify-content: center;
      li {
        height: 80px;
        font-size: 16px;
        line-height: 80px;
        &:not(:first-child) {
          margin-left: 120px;
        }
        a {
          height: 80px;
          display: block;
          color: #111111;
          transition: all 0.1s;
        }
        &.Menu_active {
          a {
            color: #2d64fa;
            border-bottom: 3px solid #2d64fa;
          }
        }
        &:hover {
          a {
            color: #2d64fa;
            border-bottom: 3px solid #2d64fa;
          }
        }
      }
    }
  }
}
</style>
