import axios from 'axios'
import { Message } from 'element-ui'
import API from './api/Api'

let instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 10000
})

const Http = {} //请求方法容器

for (let key in API) {
  let api = API[key] // url method
  Http[key] = async function (
    params, // 请求参数
    isFormData = false, //是否为formdata请求
    config = {} //配置参数
  ) {
    let newParams = {}
    // 判断是否为form=data
    if (params && isFormData) {
      newParams = new FormData()
      for (let i in params) {
        newParams.append(i, params[i])
      }
    } else {
      newParams = params
    }
    //不同请求的判断
    let response = {} // 返回的数据
    if (api.method === 'put' || api.method === 'post' || api.method === 'patch') {
      try {
        response = await instance[api.method](api.url, newParams, config)
      } catch (err) {
        response = err
      }
    } else if (api.method === 'delete' || api.method === 'get') {
      config.params = newParams
      try {
        response = await instance[api.method](api.url, config)
      } catch (err) {
        response = err
      }
    }
    return response // 返回响应值
  }
}

const ERR_CODE_LIST = {
  //常见错误码列表
  [400]: '请求错误',
  [401]: '登录失效或在其他地方已登录',
  [403]: '拒绝访问',
  [404]: '请求地址出错',
  [408]: '请求超时',
  [500]: '服务器内部错误',
  [501]: '服务未实现',
  [502]: '网关错误',
  [503]: '服务不可用',
  [504]: '网关超时',
  [505]: 'HTTP版本不受支持'
}

// 请求拦截器
instance.interceptors.request.use(
  (config) => {
    return config
  },
  () => {}
)

//响应拦截器
instance.interceptors.response.use(
  (res) => {
    return res.data
  },
  (error) => {
    if (!error.response) {
      Message.error('服务器连接错误')
    } else {
      const errCode = error.response.status //错误码
      const errMsg = ERR_CODE_LIST[errCode] //错误消息
      Message.error(errMsg)
    }
  }
)

export default Http

//请求应用
// GET
// async 方法名(){
//     let {code,data,message} = await this.$Http.getContactList({参数名:参数值})
// }
// POST
// async 方法名(){
//     let {code,data,message} = await this.$Http.getContactList(参数)
// }
// form-data请求
// async 方法名(){
//     let {code,data,message} = await this.$Http.getContactList(参数,是否form-data请求(true,false))
// }
