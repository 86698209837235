const Menu = [
  {
    name: '走进西索',
    children: [
      {
        name: '集团介绍',
        href: '/Introduce.html'
      },
      {
        name: '创始人致辞',
        href: '/Founder.html'
      },
      {
        name: '品牌理念',
        href: '/Idea.html'
      },
      {
        name: '旗下品牌',
        href: '/Brand.html'
      },
      {
        name: '发展历程',
        href: '/Course.html'
      }
      // {
      //   name: '高管团队',
      //   href: '/Team.html'
      // }
    ]
  },
  {
    name: '企业文化',
    children: [
      {
        name: '西索学社',
        href: '/Academy.html'
      },
      {
        name: '社会责任',
        href: '/Duty.html'
      },
      {
        name: '集团活动',
        href: '/Activity.html'
      }
    ]
  },
  {
    name: '核心业务',
    children: [
      {
        name: '国际学分',
        href: '/Curriculum.html'
      },
      {
        name: '留学服务',
        href: '/Service.html'
      },
      //{
      //  name: '背景提升',
      //  href: '/Promote.html'
      //},
      //{
      //  name: '求职辅导',
      //  href: '/Coach.html'
      //},
      //{
      //  name: '国际OSSD教育',
      //  href: '/OSSD.html'
      //},
      {
        name: '国际MBA',
        href: '/MBA.html'
      },
      {
        name: '特色项目',
        href: '/Feature.html'
      }
      //{
      //  name: '留学生创业人才培育',
      //  href: '/Cultivation.html'
      //},
      //{
      //  name: '科技教育产品研发',
      //  href: '/Product.html'
      //}
    ]
  },
  {
    name: '人力资源',
    children: [
      {
        name: '人才观',
        href: '/TalentView.html'
      },
      {
        name: '职业发展',
        href: '/Develop.html'
      },
      {
        name: '招聘职位',
        href: '/Recruit.html'
      }
    ]
  },
  {
    name: '新闻中心',
    children: [
      {
        name: '动态资讯',
        href: '/Dynamic.html'
      },
      {
        name: '企业新闻',
        href: '/Enterprise.html'
      }
    ]
  },
  {
    name: '品牌联盟',
    children: [
      {
        name: '公司信息',
        href: '/Information.html'
      },
      {
        name: '大师团队',
        href: '/Master.html'
      },
      {
        name: '合作伙伴',
        href: '/Cooperation.html'
      }
    ]
  },
  {
    name: '联系我们',
    children: [
      {
        name: '联系我们',
        href: '/Contact.html'
      }
    ]
  }
]
export default Menu
