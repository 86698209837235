<template>
  <div class="Head">
    <div class="Nav" :class="isWhite ? 'isWhite' : ''">
      <a href="/index.html">
        <img :src="isWhite ? 'http://cso-group.oss-cn-hongkong.aliyuncs.com/CISO/common/img/logo_white.png' : 'http://cso-group.oss-cn-hongkong.aliyuncs.com/CISO/common/img/logo.png'"
      /></a>
      <div class="Nav_r">
        <div class="Commonly_menu">
          <ul>
            <li v-for="(item, index) in CommonliMenu" :key="index">
              <a :href="item.href">{{ item.text }}</a>
            </li>
          </ul>
        </div>
        <div class="Nav_icon">
          <ul>
            <li @click="isEN = !isEN">{{ isEN ? 'CN' : 'EN' }}</li>
            <li class="MenuIcon" :class="isMenu ? 'isClose' : ''" @click="isMenuShow" v-ClickOutside="isContactHidden">
              <span></span>
              <span :class="isMenu ? 'isOpacity' : ''"></span>
              <span></span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="Menu" :class="isMenu ? 'isMenu' : ''">
      <span></span>
      <ul>
        <li v-for="(item, index) in Menu" :key="index">
          <div class="title">
            <div class="Menu_icon">
              <img :src="`http://cso-group.oss-cn-hongkong.aliyuncs.com/CISO/common/img/Menu_icon${index + 1}.png`" />
            </div>
            <p>{{ item.name }}</p>
          </div>
          <div class="subMenu">
            <ul>
              <li v-for="subItem in item.children" :key="subItem.name">
                <a :href="subItem.href">{{ subItem.name }}</a>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import Menu from '../../plugins/Menu'
export default {
  props: {
    isWhite: {
      type: Boolean,
      default: false
    },
    isMenu: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      CommonliMenu: [
        {
          href: '/Introduce.html',
          text: '走进西索'
        },
        {
          href: '/Curriculum.html',
          text: '核心项目'
        },
        {
          href: '/Information.html',
          text: '品牌联盟'
        }
      ],
      isEN: false,
      Menu: Menu
    }
  },
  methods: {
    isMenuShow() {
      this.$emit('isMenuShow', !this.isMenu)
    },
    isContactHidden() {
      this.$emit('isMenuShow', false)
    }
  },
  directives: {
    ClickOutside: {
      bind(el, binding) {
        function documentHandler(e) {
          // 如果是元素本体则返回
          if (el.contains(e.target)) return false
          if (binding.expression) {
            // 有绑定函数，则执行
            binding.value(e)
          }
        }
        el.__vueClickOutside__ = documentHandler
        document.addEventListener('click', documentHandler)
      },
      update() {},
      unbind(el) {
        document.removeEventListener('click', el.__vueClickOutside__)
        delete el.__vueClickOutside__
      }
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../assets/common/css/Head.less';
</style>
